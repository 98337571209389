import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/common/loader/lazyLoader";
const ListCoupon = lazy(() => import("../../components/coupon/listCoupon"));

const RefundCashback = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <ListCoupon />
    </Suspense>
  );
};

export default RefundCashback;
