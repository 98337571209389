import React, { Suspense, lazy } from "react";
import LazyLoader from "../../../components/common/loader/lazyLoader";
const Add = lazy(() => import("../../../components/privacyPolicy/aboutUs/add"));

const AddPage = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <Add />
    </Suspense>
  );
};

export default AddPage;
