import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/common/loader/lazyLoader";
const SalesReport = lazy(() => import("../../components/report/salesReport"));

const SalesReportPage = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <SalesReport />
    </Suspense>
  );
};

export default SalesReportPage;
