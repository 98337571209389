import axiosInstance from "../utils/axios/axios";
import baseUrl from "../utils/baseUrl/baseUrl";
import axios from "axios";
import {
  successToast,
  errorToast,
} from "../utils/notificationAlert/notificationAlert";
import store from "../redux/store";
import {
  hideLoader,
  showLoader,
} from "../redux/features/settings/setttingsSlice";
import { getToken } from "../utils/sessionHelper/sessionHelper";
import {
  setDetails,
  setList,
  setTotal,
} from "../redux/features/category/categorySlice";

const AxiosHeader = {
  withCredentials: true,
  headers: { token: getToken(), "Content-Type": "multipart/form-data" },
};

export let createCategoryRequest = async (name) => {
  let postBody = {
    name,
  };
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/create-category";
    let res = await axios.post(URL, postBody, AxiosHeader);
    store.dispatch(hideLoader());

    if (res.data.status === "success") {
      successToast("Category create success");
      return true;
    } else if (res.data.data.keyPattern.name === 1) {
      errorToast(`"${postBody.name}" already exits`);
      return false;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    errorToast("Something Went Wrong");
    return false;
  }
};
export let listCategoryRequest = async (pageNo, perPage, searchKeyword) => {
  try {
    store.dispatch(showLoader());
    let URL = `/dropdown-category/${pageNo}/${perPage}/${searchKeyword}`;
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());
    console.log(res.data);
    if (res.data.status === "success") {
      if (res.data.data?.rows.length > 0) {
        store.dispatch(setList(res.data?.data?.rows));
        store.dispatch(setTotal(res.data?.data?.total));
        return true;
      } else {
        errorToast("Data not found!");
        store.dispatch(setList([]));
        store.dispatch(setTotal([]));
        return false;
      }
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    errorToast("Something Went Wrong");
    return false;
  }
};
export let deleteCategoryRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = `/delete-category/${id}`;
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());
    if (res.data.data.deletedCount === 1) {
      successToast("Category delete success");
      return true;
    } else if (res.data.status === "associate") {
      errorToast(res.data.data);
      return false;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    errorToast("Something Went Wrong");
    return false;
  }
};
export let detailsCategoryRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = `/category-details/${id}`;
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());
    if (res.data.status === "success") {
      if (res.data.data.length > 0) {
        store.dispatch(setDetails(res.data.data));
      } else {
        errorToast("Data not found");
        return false;
      }
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    errorToast("Something Went Wrong");
    return false;
  }
};

export let updateCategory = async (id, category) => {
  let reqBody = { name: category };
  try {
    store.dispatch(showLoader());
    let URL = `${baseUrl}/update-category/${id}`;
    let res = await axiosInstance.post(URL, reqBody);
    store.dispatch(hideLoader());
    if (
      res?.data?.status === "success" &&
      res?.data?.data?.modifiedCount === 1
    ) {
      successToast("Category update success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    errorToast("Something Went Wrong");
    return false;
  }
};
