import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formValue: {
    brandId: "",
    categoryId: "",
    isCategoryBrandB1G1: false,
    isCategoryBrandB2G1: false,
  },
};

const setOffersSlice = createSlice({
  name: "setOffers",
  initialState,
  reducers: {
    setFormValue(state, actions) {
      state.formValue[actions.payload.Name] = actions.payload.Value;
    },

    resetFormValue(state) {
      state.formValue = initialState.formValue;
    },
  },
});

export const { setFormValue, resetFormValue } = setOffersSlice.actions;

export default setOffersSlice.reducer;
