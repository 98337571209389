import axiosInstance from "../utils/axios/axios";
import {
  successToast,
  errorToast,
} from "../utils/notificationAlert/notificationAlert";
import store from "../redux/store";
import {
  hideLoader,
  showLoader,
} from "../redux/features/settings/setttingsSlice";

import {
  setCancelledLast30Days,
  setListAdmin,
  setListUser,
  setOrderLast30Days,
  setRunningOrderLast30Days,
  setTotalCancelledAmount,
  setTotalCancelledCount,
  setTotalOrderAmount,
  setTotalOrderCount,
  setTotalRunningOrderAmount,
  setTotalRunningOrderCount,
  setTotalSalesAmount,
  setTotalSalesCount,
  setSalesLast30Days,
  setTotalReturnedAmount,
  setReturnedLast30Days,
  setTotalReturnedCount,
} from "../redux/features/summary/summarySlice";
import { sessionDestroy } from "../utils/sessionHelper/sessionHelper";

export let salesRequest = async () => {
  try {
    store.dispatch(showLoader());
    let URL = `/sales-summary`;
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());

    if (res.data.status === "success") {
      if (res.data.data[0].totalDeliverd.length > 0) {
        store.dispatch(
          setTotalSalesCount(res.data.data[0].totalDeliverd[0].count)
        );
        store.dispatch(
          setTotalSalesAmount(res.data.data[0].total[0].totalAmount)
        );
        store.dispatch(setSalesLast30Days(res.data.data[0].last30Days));

        return true;
      } else {
        store.dispatch(setTotalSalesCount(0));
        store.dispatch(setTotalSalesAmount(0));
        store.dispatch(setSalesLast30Days([]));

        return false;
      }
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    if (
      e?.response?.status === 401 &&
      e?.response?.data?.status === "unauthorized"
    ) {
      errorToast("Authentication fail. please login again");
      sessionDestroy();
      return false;
    } else {
      store.dispatch(hideLoader());
      errorToast("Something Went Wrong");
      return false;
    }
  }
};

export let cancelledRequest = async () => {
  try {
    store.dispatch(showLoader());
    let URL = `/cancel-summary`;
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());

    if (res.data.status === "success") {
      if (res?.data?.data?.[0]?.totalCancelled?.length > 0) {
        store.dispatch(
          setTotalCancelledCount(
            res?.data?.data?.[0].totalCancelled?.[0]?.count
          )
        );
        store.dispatch(
          setTotalCancelledAmount(res?.data?.data?.[0]?.total?.[0]?.totalAmount)
        );
        store.dispatch(
          setCancelledLast30Days(res?.data?.data?.[0]?.last30Days)
        );

        return true;
      } else {
        store.dispatch(setTotalCancelledCount(0));
        store.dispatch(setTotalCancelledAmount(0));
        store.dispatch(setCancelledLast30Days([]));

        return false;
      }
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    if (
      e?.response?.status === 401 &&
      e?.response?.data?.status === "unauthorized"
    ) {
      // errorToast("Authentication fail. please login again");
      sessionDestroy();
      return false;
    } else {
      store.dispatch(hideLoader());
      errorToast("Something Went Wrong");
      return false;
    }
  }
};

export let returnedRequest = async () => {
  try {
    store.dispatch(showLoader());
    let URL = `/return-summary`;
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());

    if (res.data.status === "success") {
      if (res?.data?.data?.[0]?.totalReturned?.length > 0) {
        store.dispatch(
          setTotalReturnedCount(res?.data?.data?.[0]?.totalReturned?.[0]?.count)
        );
        store.dispatch(
          setTotalReturnedAmount(res?.data?.data?.[0]?.total?.[0]?.totalAmount)
        );
        store.dispatch(setReturnedLast30Days(res?.data?.data?.[0]?.last30Days));

        return true;
      } else {
        store.dispatch(setTotalReturnedCount(0));
        store.dispatch(setTotalReturnedAmount(0));
        store.dispatch(setReturnedLast30Days([]));

        return false;
      }
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    if (
      e?.response?.status === 401 &&
      e?.response?.data?.status === "unauthorized"
    ) {
      // errorToast("Authentication fail. please login again");
      sessionDestroy();
      return false;
    } else {
      store.dispatch(hideLoader());
      errorToast("Something Went Wrong");
      return false;
    }
  }
};

export let runningOrderRequest = async () => {
  try {
    store.dispatch(showLoader());
    let URL = `/running-order-summary`;
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());

    if (res?.data?.status === "success") {
      if (res?.data?.data?.[0]?.runningOrder?.length > 0) {
        store.dispatch(
          setTotalRunningOrderCount(
            res?.data?.data?.[0]?.runningOrder[0]?.count
          )
        );
        store.dispatch(
          setTotalRunningOrderAmount(
            res?.data?.data?.[0]?.total?.[0]?.totalAmount
          )
        );
        store.dispatch(
          setRunningOrderLast30Days(res?.data?.data?.[0]?.last30Days)
        );

        return true;
      } else {
        store.dispatch(setTotalRunningOrderCount(0));
        store.dispatch(setTotalRunningOrderAmount(0));
        store.dispatch(setRunningOrderLast30Days([]));

        return false;
      }
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    if (
      e?.response?.status === 401 &&
      e?.response?.data?.status === "unauthorized"
    ) {
      // errorToast("Authentication fail. please login again");
      sessionDestroy();
      return false;
    } else {
      store.dispatch(hideLoader());
      errorToast("Something Went Wrong");
      return false;
    }
  }
};

export let orderRequest = async () => {
  try {
    store.dispatch(showLoader());
    let URL = `/order-summary`;
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());

    if (res.data.status === "success") {
      if (res?.data?.data?.[0]?.totalOrder?.length > 0) {
        store.dispatch(
          setTotalOrderCount(res?.data?.data?.[0]?.totalOrder?.[0]?.count)
        );
        store.dispatch(
          setTotalOrderAmount(res?.data?.data?.[0]?.total?.[0]?.totalAmount)
        );
        store.dispatch(setOrderLast30Days(res?.data?.data?.[0]?.last30Days));

        return true;
      } else {
        store.dispatch(setTotalOrderCount(0));
        store.dispatch(setTotalOrderAmount(0));
        store.dispatch(setOrderLast30Days([]));

        return false;
      }
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    if (
      e?.response?.status === 401 &&
      e?.response?.data?.status === "unauthorized"
    ) {
      // errorToast("Authentication fail. please login again");
      sessionDestroy();

      return false;
    } else {
      store.dispatch(hideLoader());
      errorToast("Something Went Wrong");
      return false;
    }
  }
};

export let listUsersRequest = async (pageNo, perPage, searchKeyword) => {
  try {
    store.dispatch(showLoader());
    let URL = `/all-user/${pageNo}/${perPage}/${searchKeyword}`;
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());

    if (res.data.status === "success") {
      if (res?.data?.data?.[0]?.total?.length > 0) {
        store.dispatch(setListUser(res?.data?.data?.[0]?.total?.[0]?.count));
        return true;
      } else {
        store.dispatch(setListUser(0));

        return false;
      }
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    if (
      e?.response?.status === 401 &&
      e?.response?.data?.status === "unauthorized"
    ) {
      // errorToast("Authentication fail. please login again");
      sessionDestroy();
      return false;
    } else {
      store.dispatch(hideLoader());
      errorToast("Something Went Wrong");
      return false;
    }
  }
};

export let listAdminRequest = async (pageNo, perPage, searchKeyword) => {
  try {
    store.dispatch(showLoader());
    let URL = `/all-admin/${pageNo}/${perPage}/${searchKeyword}`;
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());

    if (res.data.status === "success") {
      if (res.data.data[0].total.length > 0) {
        store.dispatch(setListAdmin(res.data.data[0].total[0].count));
        return true;
      } else {
        store.dispatch(setListAdmin(0));

        return false;
      }
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    if (
      e?.response?.status === 401 &&
      e?.response?.data?.status === "unauthorized"
    ) {
      // errorToast("Authentication fail. please login again");
      sessionDestroy();
      return false;
    } else {
      store.dispatch(hideLoader());
      errorToast("Something Went Wrong");
      return false;
    }
  }
};
