import React from "react";
import Addcategory from "../../components/category/addCategory";

const AddCategoryPage = () => {
  return (
    <div>
      <Addcategory />
    </div>
  );
};

export default AddCategoryPage;
