import axios from "axios";
import { getToken } from "../sessionHelper/sessionHelper";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    token: getToken(),
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default instance;
