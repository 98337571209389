import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { MyTextField } from "../common/myComponents/myTextField";
import Paper from "@mui/material/Paper";
import { Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import {
  resetDetailsSocialLink,
  resetSocialLinksFormValue,
  setSocialLinksFormValue,
} from "../../redux/features/webSettings/webSetttingsSlice";
import { IsEmpty } from "../../utils/formValidation/formValidation";
import {
  errorToast,
  successToast,
  warningToast,
} from "../../utils/notificationAlert/notificationAlert";
import {
  addSocialLinkRequest,
  detailsSocialLinkRequest,
  updateSocialLinkRequest,
} from "../../APIRequest/webSettings/socialLinkApi";
import { useNavigate } from "react-router-dom";

const SocialLink = () => {
  const navigate = useNavigate();
  let [id, setId] = useState("");
  const socialLinksFormValue = useSelector(
    (state) => state.webSettings.socialLinksFormValue
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!IsEmpty(socialLinksFormValue.name)) {
      warningToast("Social Name is required.");
    } else if (!IsEmpty(socialLinksFormValue.socialLink)) {
      warningToast("Social Link is required.");
    } else {
      let result;
      if (id !== null && id !== "0") {
        result = await updateSocialLinkRequest(id, socialLinksFormValue);
      } else {
        result = await addSocialLinkRequest(socialLinksFormValue);
      }
      if (result) {
        navigate("/settings/list-social-link");
        store.dispatch(resetDetailsSocialLink());
        store.dispatch(resetSocialLinksFormValue());
      }
    }
  };

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    setId(params.get("id"));
    if (id) {
      detailsSocialLinkRequest(id);
    }
  }, [id]);

  return (
    <Box>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {id ? "Update Social Link" : "Add Social Link"}
        </Typography>

        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={3} sx={{ mb: 2 }}>
            <Grid item xs={12} md={6}>
              <MyTextField
                fullWidth
                id="SocialName"
                variant="outlined"
                type="text"
                onChange={(e) => {
                  store.dispatch(
                    setSocialLinksFormValue({
                      Name: "name",
                      Value: e.target.value,
                    })
                  );
                }}
                value={socialLinksFormValue.name}
                label="Social Name *"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MyTextField
                fullWidth
                id="SocialLink"
                variant="outlined"
                type="text"
                onChange={(e) => {
                  store.dispatch(
                    setSocialLinksFormValue({
                      Name: "socialLink",
                      Value: e.target.value,
                    })
                  );
                }}
                value={socialLinksFormValue.socialLink}
                label="Social Link *"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <MyTextField
                fullWidth
                id="description"
                variant="outlined"
                onChange={(e) => {
                  store.dispatch(
                    setSocialLinksFormValue({
                      Name: "description",
                      Value: e.target.value,
                    })
                  );
                }}
                value={socialLinksFormValue.description}
                label="Description"
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="submit" variant="contained">
              Save Change
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default SocialLink;
