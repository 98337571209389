import React, { Suspense, lazy } from "react";
import LazyLoader from "../../components/common/loader/lazyLoader";
const AddProduct = lazy(() => import("../../components/product/addProduct"));

const AddProductPage = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <AddProduct />
    </Suspense>
  );
};

export default AddProductPage;
