import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/common/loader/lazyLoader";
const AddSubSubCategory = lazy(() =>
  import("../../components/subSubCategory/addSubSubCategory")
);

const AddSubSubCategoryPage = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <AddSubSubCategory />
    </Suspense>
  );
};

export default AddSubSubCategoryPage;
