import { configureStore } from "@reduxjs/toolkit";
import categorySlice from "./features/category/categorySlice";
import brandSlice from "./features/brand/brandSlice";
import subCategorySlice from "./features/subCategory/subCategorySlice";
import settingsSlice from "./features/settings/setttingsSlice";
import productSlice from "./features/product/productSlice";
import couponSlice from "./features/coupon/couponSlice";
import summarySlice from "./features/summary/summarySlice";
import reportSlice from "./features/report/reportSlice";
import userAndAdminSlice from "./features/userAndAdmin/userAndAdminSlice";
import orderSlice from "./features/orders/ordersSlice";
import webSetttingsSlice from "./features/webSettings/webSetttingsSlice";
import privacyPolicySlice from "./features/privacyPolicy/privacyPolicySlice";
import multipleCurrencySlice from "./features/multipleCurrencySlice/multipleCurrencySlice";
import subSubCategorySlice from "./features/subSubCategory/subSubCategorySlice";
import setOffersSlice from "./features/setOffers/setOffersSlice";

export default configureStore({
  reducer: {
    settings: settingsSlice,
    categoryList: categorySlice,
    brandList: brandSlice,
    subCategoryList: subCategorySlice,
    subSubCategoryList: subSubCategorySlice,
    productList: productSlice,
    couponList: couponSlice,
    summary: summarySlice,
    report: reportSlice,
    userAndAdmin: userAndAdminSlice,
    orderList: orderSlice,
    webSettings: webSetttingsSlice,
    privacyPolicy: privacyPolicySlice,
    multipleCurrency: multipleCurrencySlice,
    setOffers: setOffersSlice,
  },
});
