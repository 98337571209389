import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  total: 0,
  details: [],
  orderStatus: "",
};

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setList(state, actions) {
      state.list = actions.payload;
    },
    setTotal(state, actions) {
      state.total = actions.payload;
    },
    setDetails(state, actions) {
      state.details = actions.payload;
      state.orderStatus = state.details.orderStatus;
    },
    setOrderStatus(state, actions) {
      state.orderStatus = actions.payload;
    },
  },
});

export const { setDetails, setList, setTotal, setOrderStatus } =
  orderSlice.actions;

export default orderSlice.reducer;
