import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  total: 0,
  details: [],
  dropdownCategoryList: [],
  dropdownSubCategoryList: [],
  dropdownSubSubCategoryList: [],
  dropdownBrandList: [],
  formValue: {
    productName: "",
    remark: "Regular",
    description: "",
    features: "",
    ingredients: "",
    category: "",
    subCategory: "",
    subSubCategory: "",
    brand: "",
    offers: {
      isEachProductB1G1: false,
      isEachProductB2G1: false,
    },
    price: "",
    discount: "",
    quantity: "",
    country: "",
    remarkByCategory: "",
  },
};

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setList(state, actions) {
      state.list = actions.payload;
    },
    setTotal(state, actions) {
      state.total = actions.payload;
    },
    deleteListItem(state, actions) {
      state.list = state.list.filter((item) => item._id !== actions.payload);
    },
    setDetails(state, actions) {
      state.details = actions.payload;
    },
    deleteImg(state, actions) {
      state.details[0].img = state.details[0].img.filter(
        (item) => item.public_id !== actions.payload
      );
    },
    resetDetails(state) {
      state.details = [];
    },
    setDropdownCategoryList(state, action) {
      state.dropdownCategoryList = action.payload;
    },
    setDropdownSubCategoryList(state, action) {
      state.dropdownSubCategoryList = action.payload;
    },
    setDropdownSubSubCategoryList(state, action) {
      state.dropdownSubSubCategoryList = action.payload;
    },
    setDropdownBrandList(state, action) {
      state.dropdownBrandList = action.payload;
    },
    setFormValue(state, action) {
      const { Name, Value } = action.payload;
      const keys = Name.split("."); // Split the key in case of nested keys

      if (keys.length > 1) {
        let currentState = state.formValue;

        // Traverse the state to the last key before the final property
        keys.slice(0, -1).forEach((key) => {
          if (!currentState[key]) {
            currentState[key] = {}; // Initialize nested objects if they don't exist
          }
          currentState = currentState[key];
        });

        // Set the value at the final key
        currentState[keys[keys.length - 1]] = Value;
      } else {
        // If not nested, update normally
        state.formValue[Name] = Value;
      }
    },

    resetFormValue(state) {
      Object.keys(state.formValue).forEach(
        (item) => (state.formValue[item] = "")
      );
    },
  },
});

export const {
  setList,
  setTotal,
  deleteListItem,
  setDetails,
  deleteImg,
  resetDetails,
  resetFormValue,
  setFormValue,
  setDropdownBrandList,
  setDropdownCategoryList,
  setDropdownSubSubCategoryList,
  setDropdownSubCategoryList,
} = productSlice.actions;

export default productSlice.reducer;
