import React, { lazy, Suspense } from "react";
const Profile = lazy(() => import("../../components/profile/profile"));

const ProfilePage = () => {
  return (
    <>
      <Suspense>
        <Profile />
      </Suspense>
    </>
  );
};

export default ProfilePage;
