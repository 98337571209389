import { Suspense, lazy } from "react";
import LazyLoader from "../../components/common/loader/lazyLoader";
const UpdateCustomer = lazy(() =>
  import("../../components/customer/updateCustomer")
);

const UpdateCustomerPage = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <UpdateCustomer />
    </Suspense>
  );
};

export default UpdateCustomerPage;
