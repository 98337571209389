import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/common/loader/lazyLoader";
const RunningOrderReport = lazy(() =>
  import("../../components/report/runningOrderReport")
);

const RunningOrderReportPage = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <RunningOrderReport />
    </Suspense>
  );
};

export default RunningOrderReportPage;
