import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  salesReport: [],
  cancelledReport: [],
  runningOrderReport: [],
  returnOrderReport: [],
  orderReport: [],
};

const reportSlice = createSlice({
  initialState,
  name: "reports",
  reducers: {
    setSalesReport(state, actions) {
      state.salesReport = actions.payload;
    },
    setCancelledReport(state, actions) {
      state.cancelledReport = actions.payload;
    },
    setRunningOrderReport(state, actions) {
      state.runningOrderReport = actions.payload;
    },
    setReturnOrderReport(state, actions) {
      state.returnOrderReport = actions.payload;
    },
    setOrderReport(state, actions) {
      state.orderReport = actions.payload;
    },
  },
});

export const {
  setCancelledReport,
  setOrderReport,
  setRunningOrderReport,
  setSalesReport,
  setReturnOrderReport,
} = reportSlice.actions;
export default reportSlice.reducer;
