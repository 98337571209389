import * as React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { routes } from "./components/layout/routes";
import MainLayout from "./components/layout/Layout";
import { ColorModeContext } from "./configs/mode";
import { CssBaseline } from "@mui/material";
import { amber, deepPurple, grey, blueGrey } from "@mui/material/colors";
import FullScreenLoader from "./components/common/loader/fullScreenLoader";
import LoginPage from "./pages/login/loginPage";
import RegistrationPage from "./pages/registration/registrationPage";
import ForgetPasswordPage from "./pages/forgetPassword/forgetPasswordPage";
import OtpVerifyPage from "./pages/forgetPassword/otpVerifyPage";
import CreatePasswordPage from "./pages/forgetPassword/createPasswordPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getToken } from "./utils/sessionHelper/sessionHelper";
import NotFoundPage from "./pages/404/notFoundPage";
import ProfilePage from "./pages/profile/profilePage";

import { useDispatch } from "react-redux";
import { setModeRedux } from "./redux/features/settings/setttingsSlice";

export default function App() {
  let token = getToken();
  const [mode, setMode] = React.useState("light");
  // for redux store set mode name
  const dispatch = useDispatch();

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
        dispatch(setModeRedux(mode));
      },
    }),
    [dispatch, mode]
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === "light"
            ? {
                // palette values for light mode
                primary: { main: "#3db588" },
                secondary: { main: deepPurple[100] },
                divider: amber[200],
                text: {
                  primary: grey[900],
                  secondary: grey[800],
                },
              }
            : {
                // palette values for dark mode
                primary: { main: blueGrey[900] },
                secondary: { main: blueGrey[900] },
                warning: { main: blueGrey[900] },
                success: { main: blueGrey[900] },
                info: { main: blueGrey[900] },
                error: { main: blueGrey[900] },
                purple: { main: blueGrey[900] },
                divider: blueGrey[500],
                background: {
                  default: blueGrey[900],
                  paper: blueGrey[900],
                },
                text: {
                  primary: grey[50],
                  secondary: grey[500],
                },
              }),
        },

        // components: {
        //   mode,
        //   ...(mode === "light"
        //     ? {
        //         MuiInputBase: {
        //           styleOverrides: {
        //             input: {
        //               color: "#424242",
        //               "&:focus": {
        //                 MuiFormLabel: {
        //                   color: "tomato",
        //                   fontWeight: "bold",
        //                 },
        //               },
        //             },
        //           },
        //         },
        //       }
        //     : {
        //         MuiInputBase: {
        //           styleOverrides: {
        //             input: {
        //               color: "#fff",
        //               "&::before": {},
        //             },
        //           },
        //         },
        //       }),
        // },
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {token ? (
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<MainLayout />}>
                {routes}
                <Route path="/login" element={<Navigate to="/" replace />} />
                <Route path="/profile" element={<ProfilePage />} />
              </Route>
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </BrowserRouter>
        ) : (
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<NotFoundPage />} />
              <Route path="/" element={<Navigate to="/login" replace />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/registration" element={<RegistrationPage />} />
              <Route path="/forget-password" element={<ForgetPasswordPage />} />
              <Route path="/verify-otp" element={<OtpVerifyPage />} />
              <Route path="/create-password" element={<CreatePasswordPage />} />
            </Routes>
          </BrowserRouter>
        )}
        <FullScreenLoader />
      </ThemeProvider>
      <ToastContainer />
    </ColorModeContext.Provider>
  );
}
