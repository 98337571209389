import { Suspense, lazy } from "react";
import LazyLoader from "../../components/common/loader/lazyLoader";
const UpdateAdmin = lazy(() => import("../../components/admin/updateAdmin"));

const UpdateAdminPage = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <UpdateAdmin />
    </Suspense>
  );
};

export default UpdateAdminPage;
