import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/common/loader/lazyLoader";
const AddCoupon = lazy(() => import("../../components/coupon/addCoupon"));

const AddCouponPage = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <AddCoupon />
    </Suspense>
  );
};

export default AddCouponPage;
