import Swal from "sweetalert2";

export const deleteItem = async () => {
  return await Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  });
};
export const warningItem = async (text) => {
  return await Swal.fire({
    text: text,
    icon: "error",
    title: "Oops...",
    showCancelButton: true,
  });
};
export const warningItemWithConfirmBtn = async (text) => {
  return await Swal.fire({
    text: text,
    icon: "error",
    title: "Are you sure...",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, change it!",
  });
};
