import axiosInstance from "../../utils/axios/axios";
import baseUrl from "../../utils/baseUrl/baseUrl";
import {
  successToast,
  errorToast,
} from "../../utils/notificationAlert/notificationAlert";
import store from "../../redux/store";
import {
  hideLoader,
  showLoader,
} from "../../redux/features/settings/setttingsSlice";
import {
  getToken,
  sessionDestroy,
} from "../../utils/sessionHelper/sessionHelper";
import {
  setShippingAndOtherCost,
  setDetailsShippingAndOtherCost,
  setShippingAndOtherCostFormValue,
} from "../../redux/features/webSettings/webSetttingsSlice";

const AxiosHeader = {
  withCredentials: true,
  headers: { token: getToken(), "Content-Type": "multipart/form-data" },
};

//  add shipping & other cost
export let addShippingAndOtherCost = async (data) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/add-shipping-cost/";
    let res = await axiosInstance.post(URL, data);
    store.dispatch(hideLoader());

    if (res.data?.status === "success") {
      successToast("Shipping & Other Cost add success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};
export let listShippingAndOtherCostRequest = async () => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/get-shipping-cost";
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());

    if (res.data?.status === "success") {
      store.dispatch(setShippingAndOtherCost(res?.data?.data));
      return true;
    } else {
      store.dispatch(setShippingAndOtherCost([]));
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let detailsShippingAndOtherCostRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/get-shipping-cost-by-id/${id}`;
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());
    if (res.data?.status === "success") {
      let formValue = res?.data?.data?.[0];
      store.dispatch(setDetailsShippingAndOtherCost(res?.data?.data));
      store.dispatch(
        setShippingAndOtherCostFormValue({
          Name: "region",
          Value: formValue?.region,
        })
      );
      store.dispatch(
        setShippingAndOtherCostFormValue({
          Name: "method",
          Value: formValue?.method,
        })
      );
      store.dispatch(
        setShippingAndOtherCostFormValue({
          Name: "shippingCost",
          Value: formValue?.shippingCost,
        })
      );
      store.dispatch(
        setShippingAndOtherCostFormValue({
          Name: "otherCost",
          Value: formValue?.otherCost,
        })
      );
      return true;
    } else {
      store.dispatch(setDetailsShippingAndOtherCost([]));
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    console.log(e);
    return false;
  }
};

export let updateShippingAndOtherCostRequest = async (id, data) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/update-shipping-cost/${id}`;
    let res = await axiosInstance.post(URL, data);
    store.dispatch(hideLoader());

    if (
      res.data?.status === "success" &&
      res?.data?.data?.modifiedCount === 1
    ) {
      successToast("Shipping & Other Cost udpate success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let deleteShippingAndOtherCostRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/delete-shipping-cost/${id}`;
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());

    if (res.data?.status === "success" && res?.data?.data?.deletedCount === 1) {
      successToast("Deleted Shipping & Other Cost");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};
