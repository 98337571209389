import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/common/loader/lazyLoader";
const AddMultipleCurrency = lazy(() =>
  import("../../components/multipleCurrency/addMultipleCurrency")
);

const AddMultipleCurrencyPage = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <AddMultipleCurrency />
    </Suspense>
  );
};

export default AddMultipleCurrencyPage;
