import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { MyTextField } from "../common/myComponents/myTextField";
import Paper from "@mui/material/Paper";
import { Button, Typography } from "@mui/material";
import { errorToast } from "../../utils/notificationAlert/notificationAlert";

import { useSelector } from "react-redux";

import store from "../../redux/store";

import { IsEmpty } from "../../utils/formValidation/formValidation";
import {
  addShippingAndOtherCost,
  detailsShippingAndOtherCostRequest,
  updateShippingAndOtherCostRequest,
} from "../../APIRequest/webSettings/shippingAndOtherCostApi";
import {
  resetDetails,
  resetShippingCostAndOtherCost,
  setShippingAndOtherCostFormValue,
} from "../../redux/features/webSettings/webSetttingsSlice";
import { useNavigate } from "react-router-dom";

const ShippingAndOtherCost = () => {
  const navigate = useNavigate();
  let [id, setId] = useState(0);
  let shippingAndOtherCostFormValue = useSelector(
    (state) => state.webSettings.shippingAndOtherCostFormValue
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!IsEmpty(shippingAndOtherCostFormValue.region)) {
      errorToast("Region is required");
    } else if (!IsEmpty(shippingAndOtherCostFormValue.method)) {
      errorToast("Shipping method is required");
    } else if (!IsEmpty(shippingAndOtherCostFormValue.shippingCost)) {
      errorToast("Shipping cost is required");
    } else if (!IsEmpty(shippingAndOtherCostFormValue.otherCost)) {
      errorToast("Other cost is required");
    } else {
      let result;
      if (id !== null && id !== "0") {
        result = await updateShippingAndOtherCostRequest(
          id,
          shippingAndOtherCostFormValue
        );
      } else {
        result = await addShippingAndOtherCost(shippingAndOtherCostFormValue);
      }
      if (result) {
        navigate("/settings/list-shipping-and-other-cost");
        store.dispatch(resetDetails());
        store.dispatch(resetShippingCostAndOtherCost());
      }
    }
  };

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    setId(params.get("id"));
    if (id) {
      detailsShippingAndOtherCostRequest(id);
    }
  }, [id]);
  return (
    <Box>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Shipping & Other Cost
        </Typography>

        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={3} sx={{ mb: 2 }}>
            <Grid item xs={12} md={6}>
              <MyTextField
                fullWidth
                id="region"
                variant="outlined"
                type="text"
                label="Region"
                onChange={(e) => {
                  store.dispatch(
                    setShippingAndOtherCostFormValue({
                      Name: "region",
                      Value: e.target.value,
                    })
                  );
                }}
                value={shippingAndOtherCostFormValue.region}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MyTextField
                fullWidth
                id="shippingMethod"
                variant="outlined"
                type="text"
                label="Shipping method"
                onChange={(e) => {
                  store.dispatch(
                    setShippingAndOtherCostFormValue({
                      Name: "method",
                      Value: e.target.value,
                    })
                  );
                }}
                value={shippingAndOtherCostFormValue.method}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MyTextField
                fullWidth
                id="shippingCost"
                variant="outlined"
                type="text"
                label="Shipping Cost"
                onChange={(e) => {
                  store.dispatch(
                    setShippingAndOtherCostFormValue({
                      Name: "shippingCost",
                      Value: e.target.value,
                    })
                  );
                }}
                value={shippingAndOtherCostFormValue.shippingCost}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MyTextField
                fullWidth
                id="otherCost"
                variant="outlined"
                type="text"
                label="Tax or Other Cost"
                onChange={(e) => {
                  store.dispatch(
                    setShippingAndOtherCostFormValue({
                      Name: "otherCost",
                      Value: e.target.value,
                    })
                  );
                }}
                value={shippingAndOtherCostFormValue.otherCost}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="submit" variant="contained">
              {id ? "Update" : "Add"}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default ShippingAndOtherCost;
