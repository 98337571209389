import React, { Suspense, lazy } from "react";
import LazyLoader from "../../../components/common/loader/lazyLoader";
const List = lazy(() =>
  import("../../../components/privacyPolicy/termAndConditions/list")
);

const ListPage = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <List />
    </Suspense>
  );
};

export default ListPage;
