import React, { lazy, Suspense, useEffect } from "react";
import LazyLoader from "../../components/common/loader/lazyLoader";
import {
  cancelledRequest,
  listAdminRequest,
  listUsersRequest,
  orderRequest,
  returnedRequest,
  runningOrderRequest,
  salesRequest,
} from "../../APIRequest/summaryApi";
const Totals = lazy(() => import("../../components/dashboard/totals"));
const ChartsComponents = lazy(() =>
  import("../../components/dashboard/charts")
);

export default function HomePage() {
  useEffect(() => {
    salesRequest();
    cancelledRequest();
    returnedRequest();
    runningOrderRequest();
    orderRequest();
    listUsersRequest(1, 1, 0);
    listAdminRequest(1, 1, 0);
  }, []);
  return (
    <Suspense fallback={<LazyLoader />}>
      <Totals />
      <ChartsComponents />
    </Suspense>
  );
}
