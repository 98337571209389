import React from "react";
import ListCategory from "../../components/category/listCategory";

const ListCategoryPage = () => {
  return (
    <div>
      <ListCategory />
    </div>
  );
};

export default ListCategoryPage;
