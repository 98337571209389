import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pathName: "",
  sidebarDrowerOpenClose: true,
  loader: "d-none",
  mode: "dark",
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setPathName(state, actions) {
      state.pathName = actions.payload;
    },
    setSidebarDrowerOpenClose(state, actions) {
      state.sidebarDrowerOpenClose = actions.payload;
    },
    showLoader(state) {
      state.loader = "";
    },
    hideLoader(state) {
      state.loader = "d-none";
    },
    setModeRedux(state, actions) {
      state.mode = actions.payload;
    },
  },
});

export const {
  ToggleSidebarDrowerOpenClose,
  hideLoader,
  setPathName,
  setSidebarDrowerOpenClose,
  showLoader,
  setModeRedux,
} = settingsSlice.actions;

export default settingsSlice.reducer;
