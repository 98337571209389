import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allUsersForAdmin: [],
  userDetails: [],
  formValue: {
    role: "",
    isBlock: "",
  },
};

const userAndAdminSlice = createSlice({
  name: "userAndAdmin",
  initialState,
  reducers: {
    setAllUserForAdmin(state, action) {
      state.allUsersForAdmin = action.payload;
    },
    setUserDetails(state, action) {
      state.userDetails = action.payload;
    },
    setFormValue(state, action) {
      state.formValue[`${action.payload.Name}`] = action.payload.Value;
    },
    resetFormValue(state) {
      Object.keys(state.formValue).forEach(
        (item) => (state.formValue[item] = "")
      );
    },
  },
});

export let {
  setAllUserForAdmin,
  setUserDetails,
  setFormValue,
  resetFormValue,
} = userAndAdminSlice.actions;
export default userAndAdminSlice.reducer;
