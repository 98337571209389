import axiosInstance from "../../utils/axios/axios";
import baseUrl from "../../utils/baseUrl/baseUrl";
import {
  successToast,
  errorToast,
} from "../../utils/notificationAlert/notificationAlert";
import store from "../../redux/store";
import {
  hideLoader,
  showLoader,
} from "../../redux/features/settings/setttingsSlice";
import { sessionDestroy } from "../../utils/sessionHelper/sessionHelper";
import {
  setDetailsSocialLink,
  setListSocialLinks,
  setSocialLinksFormValue,
} from "../../redux/features/webSettings/webSetttingsSlice";

export let addSocialLinkRequest = async (data) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/add-social-link";
    let res = await axiosInstance.post(URL, data);
    store.dispatch(hideLoader());

    if (res.data?.status === "success") {
      successToast("Added Social Link success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let listSocialLinkRequest = async () => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/get-social-link";
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());

    if (res.data?.status === "success") {
      store.dispatch(setListSocialLinks(res?.data?.data));
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let detailsSocialLinkRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/get-social-link-by-id/${id}`;
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());

    if (res.data?.status === "success") {
      let formValue = res?.data?.data?.[0];
      store.dispatch(setDetailsSocialLink(res?.data?.data));
      store.dispatch(
        setSocialLinksFormValue({ Name: "name", Value: formValue.name })
      );
      store.dispatch(
        setSocialLinksFormValue({
          Name: "socialLink",
          Value: formValue.socialLink,
        })
      );
      store.dispatch(
        setSocialLinksFormValue({
          Name: "description",
          Value: formValue.description,
        })
      );
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let updateSocialLinkRequest = async (id, data) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/update-social-link/${id}`;
    let res = await axiosInstance.post(URL, data);
    store.dispatch(hideLoader());

    if (
      res.data?.status === "success" &&
      res?.data?.data?.modifiedCount === 1
    ) {
      successToast("Update Social Link success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let deleteSocialLinkRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/delete-social-link/${id}`;
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());

    if (res.data?.status === "success" && res.data?.data?.deletedCount == 1) {
      successToast("Deleted success!");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};
