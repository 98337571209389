import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/common/loader/lazyLoader";
const OrderReport = lazy(() => import("../../components/report/orderReport"));

const OrderReportPage = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <OrderReport />
    </Suspense>
  );
};

export default OrderReportPage;
