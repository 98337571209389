import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/common/loader/lazyLoader";
const ForgetPassword = lazy(() =>
  import("../../components/forgetPassword/forgetPassword")
);
const ForgetPasswordPage = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <ForgetPassword />
    </Suspense>
  );
};

export default ForgetPasswordPage;
