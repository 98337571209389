import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  mainSlider: [],
  mainSliderForMobile: [],
  shippingAndOtherCost: [],
  detailsShippingAndOtherCost: [],
  listSocialLinks: [],
  detailsSocialLink: [],
  shippingAndOtherCostFormValue: {
    region: "",
    method: "",
    shippingCost: "",
    otherCost: 0,
  },
  socialLinksFormValue: {
    name: "",
    socialLink: "",
    description: "",
  },
};

const webSettingsSlice = createSlice({
  name: "websettings",
  initialState,
  reducers: {
    setMainSlider(state, actions) {
      state.mainSlider = actions.payload;
    },
    setMainSliderForMobile(state, actions) {
      state.mainSliderForMobile = actions.payload;
    },
    setShippingAndOtherCost(state, actions) {
      state.shippingAndOtherCost = actions.payload;
    },
    deleteListItem(state, actions) {
      state.shippingAndOtherCost = state.shippingAndOtherCost.filter(
        (item) => item._id !== actions.payload
      );
    },
    setDetailsShippingAndOtherCost(state, actions) {
      state.detailsShippingAndOtherCost = actions.payload;
    },
    setShippingAndOtherCostFormValue(state, actions) {
      state.shippingAndOtherCostFormValue[`${actions.payload.Name}`] =
        actions.payload.Value;
    },
    resetShippingCostAndOtherCost(state) {
      state.shippingAndOtherCostFormValue = {
        region: "",
        method: "",
        shippingCost: "",
        otherCost: 0,
      };
    },
    resetDetails(state) {
      state.detailsShippingAndOtherCost = [];
    },

    setListSocialLinks(state, actions) {
      state.listSocialLinks = actions.payload;
    },

    setSocialLinksFormValue(state, actions) {
      state.socialLinksFormValue[`${actions.payload.Name}`] =
        actions.payload.Value;
    },
    setDetailsSocialLink(state, actions) {
      state.detailsSocialLink = actions.payload;
    },

    deleteSocialLinkListItem(state, actions) {
      state.listSocialLinks = state.listSocialLinks.filter(
        (item) => item._id !== actions.payload
      );
    },

    resetSocialLinksFormValue(state) {
      state.socialLinksFormValue = {
        name: "",
        socialLink: "",
        description: "",
      };
    },

    resetDetailsSocialLink(state) {
      state.detailsSocialLink = [];
    },
  },
});

export const {
  setMainSlider,
  setMainSliderForMobile,
  setShippingAndOtherCost,
  setShippingAndOtherCostFormValue,
  setDetailsShippingAndOtherCost,
  resetShippingCostAndOtherCost,
  resetDetails,
  deleteListItem,
  resetDetailsSocialLink,
  resetSocialLinksFormValue,
  setDetailsSocialLink,
  setSocialLinksFormValue,
  setListSocialLinks,
  deleteSocialLinkListItem,
} = webSettingsSlice.actions;

export default webSettingsSlice.reducer;
