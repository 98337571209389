import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  total: 0,
  details: [],
};

const brandSlice = createSlice({
  name: "brands",
  initialState,
  reducers: {
    setList(state, actions) {
      state.list = actions.payload;
    },
    setTotal(state, actions) {
      state.total = actions.payload;
    },
    deleteListItem(state, actions) {
      state.list = state.list.filter((item) => item._id !== actions.payload);
    },
    setDetails(state, actions) {
      state.details = actions.payload;
    },
    resetDetails(state) {
      state.details = [];
    },
  },
});

export const { setList, setTotal, deleteListItem, setDetails, resetDetails } =
  brandSlice.actions;

export default brandSlice.reducer;
