import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPathName } from "../../redux/features/settings/setttingsSlice";

const PageWrapper = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.state) {
      dispatch(setPathName(props.state));
    }
  }, [dispatch, props]);

  return <>{props.children}</>;
};

export default PageWrapper;
