import React, { useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import appRoutes from "./routes/appRoutes";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Avatar, Divider, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import SidebarItemCollapse from "./common/SidebarItemCollapse";
import SidebarItem from "./common/SidebarItem";
import { useWindowSize } from "../../utils/windowSize/useWindowSize";
import { setSidebarDrowerOpenClose } from "../../redux/features/settings/setttingsSlice";
import { useDispatch, useSelector } from "react-redux";
import Mode from "../../configs/mode";
import { getUserData } from "../../utils/sessionHelper/sessionHelper";
import Person2Icon from "@mui/icons-material/Person2";
import LogoutIcon from "@mui/icons-material/Logout";
import { getMultipleCurrencyRequest } from "../../APIRequest/multipleCurrencyApi";
import store from "../../redux/store";
import { setSelectedCurrency } from "../../redux/features/multipleCurrencySlice/multipleCurrencySlice";

const drawerWidth = 260;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    maxWidth: open ? "81%" : "100%",
    margin: "auto",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const MainLayout = () => {
  const userData = getUserData();
  const mode = useSelector((state) => state.settings.mode);
  const { loading, multipleCurrency, selectedCurrency } = useSelector(
    (state) => state.multipleCurrency
  );

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElCurrency, setAnchorElCurrency] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenCurrencyMenu = (event) => {
    setAnchorElCurrency(event.currentTarget);
  };

  const handleCloseCurrencyMenu = () => {
    setAnchorElCurrency(null);
  };

  const dispatch = useDispatch();
  const sidebarDrowerOpenClose = useSelector(
    (state) => state.settings.sidebarDrowerOpenClose
  );
  const windowSize = useWindowSize().width;
  const theme = useTheme();

  const handleDrawerOpen = () => {
    dispatch(setSidebarDrowerOpenClose(true));
  };

  const handleDrawerClose = () => {
    dispatch(setSidebarDrowerOpenClose(false));
  };

  useEffect(() => {
    if (windowSize <= 768) {
      dispatch(setSidebarDrowerOpenClose(false));
    } else {
      dispatch(setSidebarDrowerOpenClose(true));
    }
  }, [windowSize, dispatch]);

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  useEffect(() => {
    (async () => {
      await getMultipleCurrencyRequest();
    })();
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      {/* Appbar */}
      <AppBar
        position="fixed"
        open={sidebarDrowerOpenClose}
        sx={{ backgroundColor: "primary.main" }}
      >
        <Toolbar sx={{ maxWidth: "100%" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(sidebarDrowerOpenClose && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          {/* appbar title and setting bar and mode bar */}
          <Box
            sx={{
              mr: 2,
              display: { xs: "flex", justifyContent: "space-between" },
              flexGrow: 1,
            }}
          >
            <Typography variant="h6" noWrap component="div">
              <Link
                to="/"
                style={{
                  color: "#fff",
                  textDecoration: "none",
                  display: "flex",
                }}
              >
                laramint
              </Link>
            </Typography>

            {/* menubar  */}
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Box>
                <Box>
                  <Mode />
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar
                        alt={userData?.firstName}
                        src={
                          userData?.photo?.length > 0
                            ? userData?.photo[0]?.secure_url
                            : null
                        }
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <Box
                    style={{
                      width: "300px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        py: 2,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        alt={userData?.firstName}
                        src={
                          userData?.photo?.length > 0
                            ? userData?.photo[0]?.secure_url
                            : null
                        }
                        sx={{ width: 90, height: 90 }}
                      />
                      <Typography
                        variant="h5"
                        sx={{ textAlign: "center", pt: 1 }}
                      >
                        {userData?.firstName.toUpperCase()}{" "}
                        {userData?.lastName.toUpperCase()}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider sx={{ width: 300, mb: 2 }} />

                  <Box sx={{ pb: 1 }}>
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Typography variant="button" sx={{ pl: 1 }}>
                        <Link
                          to="profile"
                          style={{
                            color: mode === "light" ? "#eee" : "black",
                            textDecoration: "none",
                            display: "flex",
                          }}
                        >
                          <Person2Icon sx={{ mr: 1 }} /> profile
                        </Link>
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Typography variant="button" sx={{ pl: 1 }}>
                        <Box
                          onClick={handleLogout}
                          style={{
                            color: mode === "light" ? "#eee" : "black",
                            textDecoration: "none",
                            display: "flex",
                          }}
                        >
                          <LogoutIcon sx={{ mr: 1 }} /> logout
                        </Box>
                      </Typography>
                    </MenuItem>
                  </Box>
                </Menu>
              </Box>

              {/* multiple currency */}
              <Box>
                <Box>
                  <Tooltip title="Open Multiple Currency">
                    <IconButton onClick={handleOpenCurrencyMenu} sx={{ p: 0 }}>
                      <Avatar
                        alt={selectedCurrency?.countryName}
                        src={selectedCurrency?.img?.slice(-1)[0]?.secure_url}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElCurrency}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElCurrency)}
                  onClose={handleCloseCurrencyMenu}
                >
                  <Box sx={{ pb: 1 }}>
                    {multipleCurrency?.map((currency) => (
                      <MenuItem
                        onClick={() => {
                          handleCloseCurrencyMenu();
                          store.dispatch(setSelectedCurrency(currency));
                        }}
                      >
                        <Typography variant="button" sx={{ pl: 1 }}>
                          <li
                            style={{
                              color: mode === "light" ? "#eee" : "black",
                              textDecoration: "none",
                              display: "flex",
                            }}
                          >
                            <img
                              src={currency?.img?.slice(-1)[0]?.secure_url}
                              alt={currency?.countryName}
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "5px",
                              }}
                            />
                            {currency?.countryName}
                          </li>
                        </Typography>
                      </MenuItem>
                    ))}
                  </Box>
                </Menu>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{
          width: sizeConfigs.sidebar.width,
          flexShrink: 0,
        }}
      >
        {/* <Sidebar /> */}
        <Drawer
          sx={{
            width: sizeConfigs.sidebar.width,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: sizeConfigs.sidebar.width,
              boxSizing: "border-box",
              backgroundColor: "primary.main",
              color: colorConfigs.sidebar.color,
            },
          }}
          variant="persistent"
          anchor="left"
          open={sidebarDrowerOpenClose}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon sx={{ color: "rgba(255, 255, 255, 0.7)" }} />
              ) : (
                <ChevronRightIcon sx={{ color: "rgba(255, 255, 255, 0.7)" }} />
              )}
            </IconButton>
          </DrawerHeader>
          <List disablePadding>
            <Toolbar sx={{ marginBottom: "20px" }}>
              <Stack
                sx={{ width: "100%" }}
                direction="row"
                justifyContent="center"
              >
                <Link to="/">
                  <img
                    alt="logo"
                    width={200}
                    height={80}
                    src="/lara-mint-logo1.png"
                  />
                </Link>
              </Stack>
            </Toolbar>
            {appRoutes.map((route, index) =>
              route.sidebarProps ? (
                route.child ? (
                  <SidebarItemCollapse item={route} key={index} />
                ) : (
                  <SidebarItem item={route} key={index} />
                )
              ) : null
            )}
          </List>
        </Drawer>
      </Box>
      {/* Main */}
      <Main open={sidebarDrowerOpenClose}>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  );
};

export default MainLayout;
