import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/common/loader/lazyLoader";
const OtpVerify = lazy(() =>
  import("../../components/forgetPassword/otpVerify")
);

const OtpVerifyPage = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <OtpVerify />
    </Suspense>
  );
};

export default OtpVerifyPage;
