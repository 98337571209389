import axios from "axios";
import axiosInstant from "../utils/axios/axios";
import store from "../redux/store";
import baseUrl from "../utils/baseUrl/baseUrl";
import {
  setDetails,
  setList,
  setLoading,
  setMultipleCurrency,
  setSelectedCurrency,
} from "../redux/features/multipleCurrencySlice/multipleCurrencySlice";
import { getToken } from "../utils/sessionHelper/sessionHelper";
import {
  hideLoader,
  showLoader,
} from "../redux/features/settings/setttingsSlice";
import {
  errorToast,
  successToast,
} from "../utils/notificationAlert/notificationAlert";

const AxiosHeader = {
  withCredentials: true,
  headers: { token: getToken(), "Content-Type": "multipart/form-data" },
};

export let createMultipleCurrencyRequest = async (data) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/create-multipleCurrency";
    let res = await axios.post(URL, data, AxiosHeader);
    store.dispatch(hideLoader());

    if (res.data.status === "success" && res.data.fileResult.length > 0) {
      successToast("Currency created success");
      return true;
    } else if (res.data.data.keyPattern.countryName === 1) {
      errorToast(`"${data?.countryName}" already exits`);
      return false;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    errorToast("Something Went Wrong");
    return false;
  }
};

export let listMultipleCurrencyRequest = async (
  pageNo,
  perPage,
  searchKeyword
) => {
  try {
    store.dispatch(setLoading(true));
    let URL =
      baseUrl + `/list-multiple-currency/${pageNo}/${perPage}/${searchKeyword}`;
    let res = await axios.get(URL, AxiosHeader);
    store.dispatch(setLoading(false));
    if (res.data.status === "success" && res?.data?.data[0]?.rows?.length > 0) {
      store.dispatch(setList(res?.data?.data[0]?.rows));
      return true;
    } else {
      return false;
    }
  } catch (e) {
    store.dispatch(setLoading(false));
    return false;
  }
};

export let detailsMultipleCurrencyRequest = async (id) => {
  try {
    store.dispatch(setLoading(true));
    let URL = baseUrl + `/multiple-currency-details/${id}`;
    let res = await axios.get(URL, AxiosHeader);
    store.dispatch(setLoading(false));
    if (res.data.status === "success" && res?.data?.data?.length > 0) {
      store.dispatch(setDetails(res?.data?.data));
      return true;
    } else {
      return false;
    }
  } catch (e) {
    store.dispatch(setLoading(false));
    return false;
  }
};

export let updateMultipleCurrencyRequest = async (id, data) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/update-multiple-currency/${id}`;
    let res = await axios.post(URL, data, AxiosHeader);
    store.dispatch(hideLoader());

    if (
      res.data.status === "success" &&
      res.data.infoResult.modifiedCount === 1
    ) {
      successToast("Currency update success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    errorToast("Something Went Wrong");
    return false;
  }
};

export let deleteMultipleCurrencyRequest = async (id) => {
  try {
    store.dispatch(setLoading(true));
    let URL = baseUrl + `/delete-multiple-currency/${id}`;
    let res = await axios.get(URL, AxiosHeader);
    store.dispatch(setLoading(false));
    if (res.data.status === "success" && res?.data?.data?.deletedCount > 0) {
      successToast("Currency deleted success");
      return true;
    } else {
      return false;
    }
  } catch (e) {
    store.dispatch(setLoading(false));
    return false;
  }
};

export let deleteImgMultipleCurrencyRequest = async (id, imgId) => {
  try {
    store.dispatch(setLoading(true));
    let URL = baseUrl + `/delete-multiple-currency-img/${id}`;
    let res = await axiosInstant.post(URL, { imgId });
    store.dispatch(setLoading(false));
    if (
      res.data.status === "success" &&
      res?.data?.fileResult?.result == "ok"
    ) {
      successToast("Image Multiple Currency success");
      return true;
    } else {
      return false;
    }
  } catch (e) {
    store.dispatch(setLoading(false));
    return false;
  }
};

// its for drowdown headers
export let getMultipleCurrencyRequest = async () => {
  try {
    store.dispatch(setLoading(true));
    let URL = baseUrl + "/list-multiple-currency/1/1000/0";
    let res = await axios.get(URL, AxiosHeader);
    store.dispatch(setLoading(false));
    if (res.data.status === "success" && res?.data?.data[0]?.rows?.length > 0) {
      store.dispatch(setMultipleCurrency(res?.data?.data[0]?.rows));

      let allCurrencies = res?.data?.data[0]?.rows;
      if (allCurrencies?.length > 0) {
        let kuwait = allCurrencies.find(
          (item) => item.countryName.toLowerCase() == "kuwait"
        );

        if (Object.keys(kuwait || {}).length > 0) {
          store.dispatch(setSelectedCurrency(kuwait));
        } else {
          store.dispatch(setSelectedCurrency(res?.data?.data[0]?.rows?.[0]));
        }
      }

      return true;
    } else {
      return false;
    }
  } catch (e) {
    store.dispatch(setLoading(false));
    return false;
  }
};
