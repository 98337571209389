import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/common/loader/lazyLoader";
const ListMultipleCurrency = lazy(() =>
  import("../../components/multipleCurrency/listMultipleCurrency")
);

const ListMultipleCurrencyPage = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <ListMultipleCurrency />
    </Suspense>
  );
};

export default ListMultipleCurrencyPage;
