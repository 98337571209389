import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/common/loader/lazyLoader";
const AddCategoryWiseB2G1Offers = lazy(() =>
  import("../../components/setOffers/categoryB2G1")
);

const CategoryB2G1Page = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <AddCategoryWiseB2G1Offers />
    </Suspense>
  );
};

export default CategoryB2G1Page;
