import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  details: [],
  formValue: "",
};

const privacyPolicySlice = createSlice({
  name: "privacyPolicy",
  initialState,
  reducers: {
    setList(state, actions) {
      state.list = actions.payload;
    },
    setDetails(state, actions) {
      state.details = actions.payload;
    },
    setFormValue(state, actions) {
      state.formValue = actions.payload;
    },
    deleteListItem(state, actions) {
      state.list = state.list.filter((item) => item._id !== actions.payload);
    },

    resetFormValue(state) {
      state.formValue = "";
    },
    resetDetails(state) {
      state.details = [];
    },
  },
});

export const {
  setDetails,
  setList,
  setFormValue,
  deleteListItem,
  resetDetails,
  resetFormValue,
} = privacyPolicySlice.actions;

export default privacyPolicySlice.reducer;
