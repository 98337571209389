import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/common/loader/lazyLoader";
const CancelledReport = lazy(() =>
  import("../../components/report/cancelledReport")
);

const CancelledReportPage = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <CancelledReport />
    </Suspense>
  );
};

export default CancelledReportPage;
