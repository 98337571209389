import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  total: 0,
  details: [],
  dropdownSubCategoryList: [],
  formValue: {
    subCategoryId: "",
  },
};

const subSubCategorySlice = createSlice({
  name: "subSubCategories",
  initialState,
  reducers: {
    setList(state, actions) {
      state.list = actions.payload;
    },
    setTotal(state, actions) {
      state.total = actions.payload;
    },
    deleteListItem(state, actions) {
      state.list = state.list.filter((item) => item._id !== actions.payload);
    },
    setDetails(state, actions) {
      state.details = actions.payload;

      var matchingDetails = state.dropdownSubCategoryList?.find((obj) =>
        obj?.subSubCategoryId?.some((item) => item === state.details[0]?._id)
      );
      state.formValue.subCategoryId = matchingDetails?._id;
    },
    resetDetails(state) {
      state.details = [];
    },
    setDropdownSubCategoryList(state, actions) {
      state.dropdownSubCategoryList = actions.payload;
    },
    setFormValue(state, actions) {
      state.formValue = {
        ...state.formValue,
        [actions.payload.Name]: actions.payload.Value,
      };
    },
  },
});

export const {
  setList,
  setTotal,
  deleteListItem,
  setDetails,
  resetDetails,
  setDropdownSubCategoryList,
  setFormValue,
} = subSubCategorySlice.actions;

export default subSubCategorySlice.reducer;
