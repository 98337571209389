import React, { Suspense, lazy } from "react";
import LazyLoader from "../../components/common/loader/lazyLoader";
const ListCustomer = lazy(() =>
  import("../../components/customer/listCustomer")
);

const ListCustomerPage = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <ListCustomer />
    </Suspense>
  );
};

export default ListCustomerPage;
