import axiosInstance from "../../utils/axios/axios";
import baseUrl from "../../utils/baseUrl/baseUrl";
import {
  successToast,
  errorToast,
} from "../../utils/notificationAlert/notificationAlert";
import store from "../../redux/store";
import {
  hideLoader,
  showLoader,
} from "../../redux/features/settings/setttingsSlice";
import {
  getToken,
  sessionDestroy,
} from "../../utils/sessionHelper/sessionHelper";
import { setMainSliderForMobile } from "../../redux/features/webSettings/webSetttingsSlice";

const AxiosHeader = {
  withCredentials: true,
  headers: { token: getToken(), "Content-Type": "multipart/form-data" },
};

export let addMainSliderForMobileRequest = async (data) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/add-main-slider-for-mobile";
    let res = await axiosInstance.post(URL, data, AxiosHeader);
    store.dispatch(hideLoader());
    console.log(res.data);
    if (res.data?.status === "success" && res.data?.fileResult?.length > 0) {
      successToast("Added main slider success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let getAllMainSliderForMobileRequest = async () => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/get-all-main-slider-for-mobile/1/100/0";
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());
    if (
      res.data?.status === "success" &&
      res.data?.data?.[0]?.total?.[0]?.count > 0
    ) {
      store.dispatch(setMainSliderForMobile(res.data?.data?.[0]?.rows));
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let deleteMainSliderForMobileRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/delete-main-slider-for-mobile/${id}`;
    let res = await axiosInstance.post(URL);
    store.dispatch(hideLoader());
    if (res.data?.status === "success" && res.data?.data?.deletedCount == 1) {
      successToast("Deleted main slider success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};
