import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/common/loader/lazyLoader";
const AddSubCategory = lazy(() =>
  import("../../components/subCategory/addSubCategory")
);

const AddSubCategoryPage = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <AddSubCategory />
    </Suspense>
  );
};

export default AddSubCategoryPage;
