import React, { useEffect } from "react";
import moment from "moment";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import DeleteIcon from "@mui/icons-material/Delete";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import PriceConverterByCountry from "../../utils/priceConverterByCountry/priceConverterByCountry";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import NativeSelect from "@mui/material/NativeSelect";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";

import { useWindowSize } from "../../utils/windowSize/useWindowSize";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteShippingAndOtherCostRequest,
  listShippingAndOtherCostRequest,
} from "../../APIRequest/webSettings/shippingAndOtherCostApi";
import { deleteItem } from "../../utils/notificationAlert/swalAlert";
import { deleteListItem } from "../../redux/features/webSettings/webSetttingsSlice";
import store from "../../redux/store";

const columns = [
  { id: "#no", label: "#No", minWidth: 2, align: "center" },
  { id: "region", label: "Region", minWidth: 150, align: "center" },
  { id: "method", label: "Shipping Method", minWidth: 150 },
  { id: "shippingCost", label: "Shipping Cost", minWidth: 150 },
  { id: "otherCost", label: "Other Cost", minWidth: 150 },
  {
    id: "createdAt",
    label: "Created Date",
    minWidth: 150,
    align: "inherit",
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 100,
  },
];

const ListShippingCostAndOtherCost = () => {
  const mode = useSelector((state) => state.settings.mode);
  let shippingAndOtherCost = useSelector(
    (state) => state.webSettings.shippingAndOtherCost
  );
  const total = useSelector((state) => state.categoryList.total);
  const [pageNo, setPageNo] = React.useState(1);
  const [perPage, setPerPage] = React.useState(5);
  const [searchKeyword, setSearchKeyword] = React.useState("0");
  const [searchTermValue, setSearchTermValue] = React.useState("0");

  const handleChangePage = (e, newPage) => {
    setPageNo(newPage);
  };

  const handleChangePerPage = (e) => {
    setPerPage(+e.target.value);
    // setPageNo(1);
  };

  const searchTerm = (e) => {
    if (e.target.value.length === 0) {
      setSearchKeyword("0");
    } else {
      setSearchTermValue(e.target.value);
    }
  };

  const handleSearch = () => {
    setPageNo(1);
    setPerPage(5);
    setSearchKeyword(searchTermValue);
  };

  const deleteShippingCost = async (id) => {
    let result = await deleteItem();
    if (result.isConfirmed) {
      let deleteResult = await deleteShippingAndOtherCostRequest(id);
      if (deleteResult) {
        store.dispatch(deleteListItem(id));
      }
    }
  };

  const TextSearch = (e) => {
    let rows = document.querySelectorAll("tbody tr");
    rows.forEach((row) => {
      row.style.display = row.innerText
        .toLowerCase()
        .includes(e.target.value.toLowerCase())
        ? ""
        : "none";
    });
  };

  useEffect(() => {
    listShippingAndOtherCostRequest();
  }, []);

  return (
    <Paper
      sx={{
        width: useWindowSize().width <= 600 ? "87vw" : "100%",
        overflowX: "auto",
        py: 1,
        px: 3,
      }}
    >
      <Box sx={{ display: useWindowSize().width <= 992 ? "" : "none" }}>
        <Typography
          sx={{ pt: 2 }}
          variant={useWindowSize().width < 600 ? "h6" : "h4"}
        >
          List Shipping Cost
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pb: 1,
        }}
      >
        <Box sx={{ display: useWindowSize().width <= 992 ? "none" : "" }}>
          <Typography
            sx={{ pt: 2 }}
            variant={useWindowSize().width < 600 ? "h6" : "h4"}
          >
            List Shipping Cost
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Paper
            sx={{
              my: 3,
              border: "1px solid #c3c3c3",
              height: useWindowSize().width <= 600 ? 35 : 46,
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              width: useWindowSize().width <= 500 ? 100 : 200,
              boxShadow: "inherit",
            }}
          >
            <InputBase
              onChange={TextSearch}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Text Search"
              inputProps={{ "aria-label": "search google maps" }}
            />
          </Paper>
          <Paper
            sx={{
              my: 3,
              justifyContent: "end",
              border: "1px solid #c3c3c3",
              height: useWindowSize().width <= 600 ? 35 : "",
              display: "flex",
              alignItems: "center",
              width: useWindowSize().width <= 500 ? 150 : 250,
              boxShadow: "inherit",
            }}
          >
            <InputBase
              onChange={searchTerm}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Deep Search"
              inputProps={{ "aria-label": "search google maps" }}
            />
            <IconButton
              type="button"
              onClick={handleSearch}
              sx={{ p: "10px" }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </Box>
      </Box>

      <TableContainer
        sx={{
          maxHeight: 420,
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {shippingAndOtherCost?.map((item, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={item._id}>
                  <TableCell
                    sx={{ borderBottom: "1px solid #ccc" }}
                    align="center"
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderBottom: "1px solid #ccc" }}
                  >
                    {item?.region}
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #ccc" }}
                    align="inherit"
                  >
                    {item?.method}
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: "1px solid #ccc" }}
                    align="inherit"
                  >
                    <PriceConverterByCountry price={item?.shippingCost} />
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: "1px solid #ccc" }}
                    align="inherit"
                  >
                    <PriceConverterByCountry price={item?.otherCost} />
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: "1px solid #ccc" }}
                    align="inherit"
                  >
                    {moment(item?.createdAt).format("DD MMM YYYY")}
                  </TableCell>
                  <TableCell
                    align="inherit"
                    sx={{ borderBottom: "1px solid #ccc" }}
                  >
                    <Link
                      to={`/settings/shipping-and-other-cost?id=${item._id}`}
                    >
                      <BorderColorIcon
                        sx={{
                          cursor: "pointer",
                          color: mode === "dark" ? "#512DA8" : "#fff",
                        }}
                      />
                    </Link>{" "}
                    <DeleteIcon
                      onClick={() => deleteShippingCost(item._id)}
                      sx={{
                        cursor: "pointer",
                        color: mode === "dark" ? "#512DA8" : "#fff",
                      }}
                      color="secondary"
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: useWindowSize().width <= 600 ? "block" : "flex",
          justifyContent: "flex-end",
          py: 4,
        }}
      >
        {/* per Page setub */}
        <Box
          sx={{
            minWidth: 160,
            pr: 3,
            pb: useWindowSize().width <= 600 ? 4 : "",
          }}
        >
          <FormControl>
            <NativeSelect defaultValue={5} onChange={handleChangePerPage}>
              <option value={5}>5 Per Page</option>
              <option value={20}>20 Per Page</option>
              <option value={50}>50 Per Page</option>
              <option value={100}>100 Per Page</option>
            </NativeSelect>
          </FormControl>
        </Box>
        {/* pagination */}
        <Pagination
          count={Math.ceil(total / perPage)}
          onChange={handleChangePage}
          variant="outlined"
          sx={{ color: "#512DA8" }}
        />
      </Box>
    </Paper>
  );
};

export default ListShippingCostAndOtherCost;
