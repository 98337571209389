import React, { Suspense, lazy } from "react";
import LazyLoader from "../../components/common/loader/lazyLoader";
const ListAdmin = lazy(() => import("../../components/admin/listAdmin"));

const ListAdminPage = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <ListAdmin />
    </Suspense>
  );
};

export default ListAdminPage;
