import React, { Suspense, lazy } from "react";
import LazyLoader from "../../components/common/loader/lazyLoader";
const ListProduct = lazy(() => import("../../components/product/listProduct"));

const ListProductPage = () => {
  return (
    <div>
      <Suspense fallback={<LazyLoader />}>
        <ListProduct />
      </Suspense>
    </div>
  );
};

export default ListProductPage;
