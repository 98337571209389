import { ListItemButton, ListItemIcon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import colorConfigs from "../../../configs/colorConfigs";
import { useWindowSize } from "../../../utils/windowSize/useWindowSize";
import { setSidebarDrowerOpenClose } from "../../../redux/features/settings/setttingsSlice";

const SidebarItem = ({ item }) => {
  const mode = useSelector((state) => state.settings.mode);

  const dispatch = useDispatch();
  const windowSize = useWindowSize().width;
  const pathName = useSelector((state) => state.settings.pathName);

  const handleDrowerOpenOrCloseResponsive = () => {
    if (windowSize <= 768) {
      dispatch(setSidebarDrowerOpenClose(false));
    }
  };

  return item.sidebarProps && item.path ? (
    <ListItemButton
      component={Link}
      to={item.path}
      onMouseDown={handleDrowerOpenOrCloseResponsive}
      sx={{
        "&: hover": {
          backgroundColor:
            mode === "light" ? "#9e9e9e" : colorConfigs.sidebar.hoverBg,
        },
        backgroundColor:
          pathName === item.state
            ? mode === "light"
              ? "#9e9e9e"
              : colorConfigs.sidebar.hoverBg
            : "unset",
        paddingY: "12px",
        paddingX: "24px",
      }}
    >
      <ListItemIcon
        sx={{
          color: colorConfigs.sidebar.color,
        }}
      >
        {item.sidebarProps.icon && item.sidebarProps.icon}
      </ListItemIcon>
      {item.sidebarProps.displayText}
    </ListItemButton>
  ) : null;
};

export default SidebarItem;
