import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // start for header dropdown
  loading: false,
  multipleCurrency: [],
  selectedCurrency: [],
  // end for header dropdown
  // For admin currency add, delete, update and read
  list: [],
  total: 0,
  details: [],
};

const multipleCurrencySlice = createSlice({
  name: "multipleCurrency",
  initialState,
  reducers: {
    // start for header dropdown
    setLoading(state, actions) {
      state.loading = actions.payload;
    },
    setMultipleCurrency(state, actions) {
      state.multipleCurrency = actions.payload;
    },
    setSelectedCurrency(state, actions) {
      state.selectedCurrency = actions.payload;
    },
    // end for header dropdown
    // For admin currency add, delete, update and read
    setList(state, actions) {
      state.list = actions.payload;
    },
    setTotal(state, actions) {
      state.total = actions.payload;
    },
    deleteListItem(state, actions) {
      state.list = state.list.filter((item) => item._id !== actions.payload);
    },
    setDetails(state, actions) {
      state.details = actions.payload;
    },
    resetDetails(state) {
      state.details = [];
    },
  },
});

export const {
  setLoading,
  setMultipleCurrency,
  setSelectedCurrency,
  deleteListItem,
  resetDetails,
  setDetails,
  setList,
  setTotal,
} = multipleCurrencySlice.actions;

export default multipleCurrencySlice.reducer;
