import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/common/loader/lazyLoader";
const AddBrandWiseB1G1Offers = lazy(() =>
  import("../../components/setOffers/brandB1G1")
);

const BrandB1G1Page = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <AddBrandWiseB1G1Offers />
    </Suspense>
  );
};

export default BrandB1G1Page;
