import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/common/loader/lazyLoader";
const AllOrder = lazy(() => import("../../components/order/allOrder"));

const AllOrderPage = () => {
  return (
    <div>
      <Suspense fallback={<LazyLoader />}>
        <AllOrder />
      </Suspense>
    </div>
  );
};

export default AllOrderPage;
