import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/common/loader/lazyLoader";
const AddBrand = lazy(() => import("../../components/brand/addBrand"));

const AddBrandPage = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <AddBrand />
    </Suspense>
  );
};

export default AddBrandPage;
