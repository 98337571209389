import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  totalSalesCount: 0,
  totalSalesAmount: 0,
  salesLast30Days: [],

  totalCancelledCount: 0,
  totalCancelledAmount: 0,
  cancelledLast30Days: [],

  totalReturnedCount: 0,
  totalReturnedAmount: 0,
  returnedLast30Days: [],

  totalRunningOrderCount: 0,
  totalRunningOrderAmount: 0,
  runningOrderLast30Days: [],

  totalOrderCount: 0,
  totalOrderAmount: 0,
  orderLast30Days: [],

  listAdmin: 0,
  listUser: 0,
};

const summarySlice = createSlice({
  initialState,
  name: "summaries",
  reducers: {
    setTotalSalesCount(state, actions) {
      state.totalSalesCount = actions.payload;
    },
    setTotalSalesAmount(state, actions) {
      state.totalSalesAmount = actions.payload;
    },
    setSalesLast30Days(state, actions) {
      state.salesLast30Days = actions.payload;
    },

    setTotalCancelledCount(state, actions) {
      state.totalCancelledCount = actions.payload;
    },
    setTotalCancelledAmount(state, actions) {
      state.totalCancelledAmount = actions.payload;
    },
    setCancelledLast30Days(state, actions) {
      state.cancelledLast30Days = actions.payload;
    },

    setTotalReturnedCount(state, actions) {
      state.totalReturnedCount = actions.payload;
    },
    setTotalReturnedAmount(state, actions) {
      state.totalReturnedAmount = actions.payload;
    },
    setReturnedLast30Days(state, actions) {
      state.returnedLast30Days = actions.payload;
    },

    setTotalRunningOrderCount(state, actions) {
      state.totalRunningOrderCount = actions.payload;
    },
    setTotalRunningOrderAmount(state, actions) {
      state.totalRunningOrderAmount = actions.payload;
    },
    setRunningOrderLast30Days(state, actions) {
      state.runningOrderLast30Days = actions.payload;
    },

    setTotalOrderCount(state, actions) {
      state.totalOrderCount = actions.payload;
    },
    setTotalOrderAmount(state, actions) {
      state.totalOrderAmount = actions.payload;
    },
    setOrderLast30Days(state, actions) {
      state.orderLast30Days = actions.payload;
    },

    setListAdmin(state, actions) {
      state.listAdmin = actions.payload;
    },
    setListUser(state, actions) {
      state.listUser = actions.payload;
    },
  },
});

export const {
  setListAdmin,
  setListUser,
  setTotalCancelledAmount,
  setTotalCancelledCount,
  setCancelledLast30Days,
  setTotalReturnedAmount,
  setReturnedLast30Days,
  setTotalReturnedCount,
  setTotalOrderAmount,
  setTotalOrderCount,
  setOrderLast30Days,
  setRunningOrderLast30Days,
  setSalesLast30Days,
  setTotalRunningOrderAmount,
  setTotalRunningOrderCount,
  setTotalSalesAmount,
  setTotalSalesCount,
} = summarySlice.actions;

export default summarySlice.reducer;
